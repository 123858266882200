// Your custom styles

.bg-landing {
  background-position: "center";
  background-size: "cover";
  background-repeat: no-repeat;
  height: "100vh";
}

.padding-landing {
  padding: 75px;
}

@media (max-width: 576px) {
  .padding-landing {
    padding: 20px;
  }

  .display-4 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

.footer {
  position: relative;
  top: 10px;
}


.cover-landing {
  height: 85vh;
  background-color: #6e6195;
  border-radius: 10px;
  padding: 0px;
}

.view-text-landing {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-self: center;
  justify-content: center;
  height: 60vh;
}

.box-1 {
  height: 50vh;
  background: #4291af;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.box-2 {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 35vh;
}

.box-3 {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 40vh;
  border-top-right-radius: 10px;
}

.box-4 {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 30vh;
}

.input-code {
  height: 100px;
  font-size: 48px;
  text-align: center;
  color: #000 !important;
  font-weight: bold
}

.md-form label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  transition: transform .2s ease-out, color .2s ease-out;
  transform-origin: 0% 100%;
  transform: translateY(12px);
  cursor: text;
  color: #000;
  font-weight: bold;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $primary;
  transition: all 0.2s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: $hoverPrimary;
  transition: all 0.2s ease-in-out;
}

.pointer {
  cursor: pointer;
}

.view-menu-bar {
  position: absolute;
  top: 22px;
  left: 20px;
}


//text styles
.text-primary {
  color: $primary !important;
}

//form
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: $primary;
  outline: 0;
  box-shadow: 0 0 0 0rem #2e2e2e;
}

//btn styles

.btn-default {
  background-color: #6e6195 !important;
  color: #fff;
}

.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show>.btn-default.dropdown-toggle {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #6e6195 !important;
}

.btn-circle {
  border-radius: 100px;
  padding: 5px 35px;
  font-size: 12px;
  text-transform: capitalize;
}

.btn-circle-danger {
  border-radius: 100px;
  padding: 5px 35px;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #ff5a5a !important
}
.btn-circle-success {
  border-radius: 100px;
  padding: 5px 35px;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #05c92ff5 !important
}
.btn-circle-link {
  background-color: $primary;
  color: #fff !important;
}

.btn-circle-cancel {
  background-color: #ff5a5a !important;
  color: #fff !important;
}

.btnLink {
  padding: 10px 0px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: $primary !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: capitalize;
  text-decoration: none !important;
}

.btn.btn-link {
  color: $primary !important;
}

.btn-auth {
  width: 65%;
  margin: 0;
  background-color: $primary !important;
  border-radius: 50px;
  padding: 8px 9px;
}

.btn-style {
  margin: 0;
  background-color: $primary !important;
  border-radius: 50px;
  padding: 5px 45px;
}

.btn-icon {
  display: inline-block;
  padding: 10px;
  background-color: #3aa6d4;
  margin-right: 15px;
}

.btn-edit {
  border-radius: 100px;
  padding: 5px;
  height: 30px;
  width: 30px;
  font-size: 12px;
  background-color: #52df78 !important;
}

.btn-delete {
  border-radius: 100px;
  padding: 5px;
  height: 30px;
  width: 30px;
  font-size: 12px;
  background-color: #ff5a5a !important;
}

.btn-delete-round {
  border-radius: 100px;
  padding: 5px;
  height: 40px;
  width: 40px;
  font-size: 12px;
  background-color: #ff5a5a !important;
}

.btn-edit-round {
  border-radius: 100px;
  padding: 5px;
  height: 35px;
  width: 35px;
  font-size: 12px;
  background-color: #6e6195 !important;
}

.btn-pause {
  width: 35px;
  height: 35px;
  margin-right: 5px !important;
  margin-Left: 10px !important;
  padding: 0;
  border-radius: 50px;
  background-color: #70cef6;
  color: #fff;
}

.btn-disabled {
  width: 35px;
  height: 35px;
  margin-right: 5px !important;
  margin-Left: 10px !important;
  padding: 0;
  pointer-events: none;
  border-radius: 50px;
  background-color: gray !important;
  color: white;
}

.btn-disabled-large {
  background-color: gray !important;
}

.btn-copy {
  width: 35px;
  height: 35px;
  margin-right: 5px !important;
  padding-top: 7px !important;
  padding: 0;
  border-radius: 50px;
  background-color: #6ee9a3;
  color: #fff !important;
}

.btn-detail {
  width: 35px;
  height: 35px;
  margin-right: 5px !important;
  padding-top: 7px !important;
  padding: 0;
  border-radius: 50px;
  background-color: $primary;
  color: #fff !important;
}

.btn-erase {
  width: 35px;
  height: 35px;
  margin-right: 5px !important;
  padding: 0;
  border-radius: 50px;
  background-color: #e96e6e;
  color: #fff !important;
}

.badge-warning {
  background-color: #fff710 !important;
  color: #000 !important;
  padding: 4px 25px;
}

//navTabs Style material
.nav-tabs {
  border-bottom: transparent;
  text-align: center;
}

.nav-tabs .nav-item {
  border-bottom: transparent;
  width: 50% !important;
  text-align: center;
}

.nav-item .nav-link:hover {
  color: $grey-darken-4 !important;
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: $grey-darken-4;
  transition: 0.35s;
  font-weight: bold;
  font-size: 14px;
}

.nav-tabs .nav-link.active {
  color: $grey-darken-4 !important;
  background-color: #fff;
  border-color: transparent;
  border-bottom: 2px solid $primary;
}

.nav-tabs .nav-link.active:hover {
  color: $grey-darken-4 !important;
  background-color: #fff;
  border-color: transparent;
  border-bottom: 2px solid $primary;
}

.nav-tabs .nav-link:hover {
  border-color: transparent;
}

// Sidebar Styles

.sidebarStyles {
  width: 260px;
  background-color: $primary;
}

// List item sidebar
.list-group-item {
  position: relative;
  display: block;
  padding: 0.85rem 1.25rem;
  margin-bottom: -1px !important;
  background-color: transparent;
  border: 0;
  color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: $hoverPrimary;
  border-color: $hoverPrimary;
  border-left: 2px solid $white;
  transition: all 0.2s ease;
  -moz-transition: 0.2s ease all;
  -webkit-transition: all 0.2s ease;
}

.list-group-item:hover {
  background-color: $hoverPrimary;
  border-left: 2px solid $white;
  transition: all 0.2s ease;
  -moz-transition: 0.2s ease all;
  -webkit-transition: all 0.2s ease;
}

.list-group .list-group-item:last-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.list-group .list-group-item:first-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.icon-list-item {
  margin-right: 15px;
  width: 20px;
}

.title-list-item {
  position: relative;
  top: 3px;
}

.title-list-item-no-icon {
  position: relative;
  top: 3px;
  left: 35px;
}

.link-logout {
  color: #fff;
  // position: absolute;
  bottom: 15px;
  left: 25px;
  cursor: pointer;
}

.link-terms {
  color: #fff;
  // position: absolute;
  bottom: 15px;
  left: 25px;
  cursor: pointer;
}

.link-logout:hover {
  opacity: 0.7;
}

.link-terms:hover {
  color: #fff;
  opacity: 0.7;
}

// Nav Bar Admin
.nav-admin {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 25px;
}

.nav-admin-mobile {
  background-color: #6e6195;
  padding: 10px;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: 575px) {
  .nav-admin-mobile {
    width: 890px;
    text-align: initial;
    img {
      margin-left: 60px;
    }

  }
}

.body {
  padding: 15px 60px;
}

@media (max-width: 576px) {
  .body {
    padding: 15px 25px;
  }
}

.img-card {
  width: 100%;
  height: 190px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.scrollbar {
  overflow: scroll;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

/* custom colors  */
.scrollbar-primary::-webkit-scrollbar {
  background-color: #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $primary;
}

//Table component

table.table thead th {
  border-top: none;
  border-bottom: none !important;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  border-bottom: 2px solid #ddd
}

.img-promotion-table {
  width: 100px;
  height: 75px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: inline-block;
  margin-right: 10px;
}

.img-promotion-detail {
  width: 100%;
  height: 350px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: inline-block;
  margin-right: 10px;
}

.username-table {
  position: relative;
  top: -10px;
}

.Customlabel {
  padding: 14px;
  cursor: pointer;
  width: 100%;
  height: 300px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0px;
}

.CustomlabelSales {
  padding: 7px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0px;
}

.img-label {
  position: absolute;
  top: 50%;
  left: 68%;
  width: 20%;
  margin: -10% 0 0 -25%;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.md-form {
  position: relative;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

/* The container */
.container-radio {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  border: 1px solid #ccc;
  position: absolute;
  top: 0px;
  left: 5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  transition: all 0.2s ease;
  -moz-transition: 0.2s ease all;
  -webkit-transition: all 0.2s ease;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input~.checkmark {
  border: 1px solid $primary;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked~.checkmark {
  background-color: $primary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked~.checkmark:after {
  display: block;
}

//Form Search

.form-group-search {
  width: 75%;
  margin: auto;
}

.form-control-search {
  background-color: #e8eaed;
  border-radius: 100px;
  height: calc(1.5em + 0.25rem + 0px);
  font-size: .9rem;
}

// Custom Checkbox
.checkbox-container {
  display: block;
  position: relative;
  // padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  opacity: 0;
  cursor: pointer;
  width: 10px;
}

.custom-checkbox {
  position: absolute;
  top: 5px;
  left: 7px;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.checkbox-container:hover input~.custom-checkbox {
  background-color: #ccc;
}

.checkbox-container input:checked~.custom-checkbox {
  background-color: $primary;
}

.custom-checkbox:after {
  content: '';
  color: #fff;
  position: absolute;
  display: none;
}

.checkbox-container input:checked~.custom-checkbox:after {
  display: block;
}

.checkbox-container .custom-checkbox:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// display feed

.display-feed {
  margin-top: -40px;
  margin-bottom: -30px;
}

.checkfeed-container {
  display: auto;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkfeed-container input {
  opacity: 0;
  cursor: pointer;
  width: 10px;
}

.feed-checkbox {
  position: absolute;
  top: 39px;
  left: 7px;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

.checkfeed-container:hover input~.feed-checkbox {
  background-color: #ccc;
}

.checkfeed-container input:checked~.feed-checkbox {
  background-color: $primary;
}

.feed-checkbox:after {
  content: '';
  color: #fff;
  position: absolute;
  display: none;
}

.checkfeed-container input:checked~.feed-checkbox:after {
  display: block;
}

.checkfeed-container .feed-checkbox:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// .check-feed {
//   text-align: right;
//   align-self: right;
// }

//total price style

.total-price {
  color: #00b427;
}

.img-promotion {
  width: 100%;
  max-height: 375px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.imgLabel {
  padding: 14px;
  cursor: pointer;
  position: relative;
  margin: 0px;
}
.imgLabelDetail {
  padding: 14px;
  position: relative;
  margin: 0px;
}

// Terms and conditions

.terms-container{
  text-align: justify;
  padding-left:  11vw; 
}

td {
  max-width: 120px;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cardHove:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.cardNull {
  background-color: #efefef
}

.cur {
  cursor: pointer;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

@media (max-width: 767px) { 
  .marginTop {
    margin-top: 320px;
  }
}

.is-red {
  color: #dc3545;
}

.label-black {
  color: #000000;
  font-weight: bold;
}

.is-red-valid {
  color: #dc3545;
  margin-top: -12px;
}