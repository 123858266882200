/*!
 * Material Design for Bootstrap 4
 * Version: MDB FREE 4.8.0
 *
 *
 * Copyright: Material Design for Bootstrap
 * https://mdbootstrap.com/
 *
 * Read the license: https://mdbootstrap.com/general/license/
 *
 *
 * Documentation: https://mdbootstrap.com/
 *
 * Getting started: https://mdbootstrap.com/docs/jquery/getting-started/download/
 *
 * Tutorials: https://mdbootstrap.com/education/bootstrap/
 *
 * Templates: https://mdbootstrap.com/templates/
 *
 * Support: https://mdbootstrap.com/support/
 *
 * Contact: office@mdbootstrap.com
 *
 * Attribution: Animate CSS, Twitter Bootstrap, Materialize CSS, Normalize CSS, Waves JS, WOW JS, Toastr, Chart.js
 *
 */

@charset "UTF-8";

// Bootstrap
@import "core/bootstrap/functions";
@import "core/bootstrap/variables";

// CORE
@import "core/mixins";
// Your custom variables
@import "custom-variables";
@import "core/colors";
@import "core/variables";
@import "core/global";
@import "core/helpers";
@import "core/typography";
@import "core/masks";
@import "core/waves";

// FREE
@import "free/animations-basic";
@import "free/modules/animations-extended/module";
@import "free/buttons";
@import "free/cards";
@import "free/dropdowns";
@import "free/input-group";
@import "free/navbars";
@import "free/pagination";
@import "free/badges";
@import "free/modals";
@import "free/carousels";
@import "free/forms";
@import "free/msc";
@import "free/footers";
@import "free/list-group";
@import "free/tables";
@import "free/depreciated";
@import "free/steppers";
@import "free/loader";
@import "free/treeview";
// Free addons
@import "addons/datatables";
@import "addons/datatables-select";
@import "addons/directives";

// Your custom styles
@import "custom-styles";
